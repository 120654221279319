

import { Vue, Component, Ref, Watch } from 'vue-property-decorator'
import { OrderDetail, OrderTypeDetail } from '@/types/newOrder.d'
import { Page } from '@/types/common'
import { ElTable } from 'element-ui/types/table'
import { ProjectUser } from '@/types/project'
import { ElForm } from 'element-ui/types/form'
import { Machine, Material } from '@/types/material'
import ImgList from './attenanceImglist.vue'

@Component({
  name: 'OperationRecordAdd',
  components: { ImgList }
})
export default class OperationRecordAdd extends Vue {
  @Ref('table') readonly table!: ElTable
  @Ref('form') readonly form!: ElForm

  imglistShow = false
  projectUserList: ProjectUser[] = []

  workerTeamList = []
  machineList: Machine[] = []
  materialList: Material[] = []

  formData = {
    projectId: '', // 项目id
    dateStr: '', // 经营日期
    absenceIdList: [], // 缺勤人员id
    attendanceIdList: [], // 出勤人员id
    flowOrderList: [], // 工单列表
    machineryList: [], // 机械
    materialList: [], // 材料
    userList: [], // 人员
    planList: [],
    summarize: '' // 工单总结
  }

  // private summarize: any = null

  rules = {
    projectId: [{ required: true, message: '请选择项目名称', trigger: 'change' }],
    attendanceIdList: [{ required: true, type: 'array', message: '请选择出勤管理员', trigger: 'change' }],
    dateStr: [{ required: true, message: '请选择日报日期', trigger: 'change' }],
    summarize: [{ required: true, message: '请输入今日总结', trigger: 'change' }, { max: 500, message: '最多500个字符', trigger: ['blur', 'change'] }]
  }

  dialogVisible = false
  workOrderForm = {
    orderSubjectInId: '',
    orderType: ''
  }

  areaList: any = []

  page = 1
  total = 0
  size = 10
  workOrderItems: OrderDetail[] = []
  typeList: OrderTypeDetail[] = []

  get operatingId () {
    return this.$route.params.operatingId
  }

  get projectList () {
    return this.$store.state.projectList
  }

  get absenceList () {
    return this.projectUserList.filter(item => {
      return !this.formData.attendanceIdList.some(id => id === item.userId)
    })
  }

  get attendanceList () {
    return this.projectUserList.filter(item => {
      return !this.formData.absenceIdList.some(id => id === item.userId)
    })
  }

  get workerTeamListData () {
    return this.workerTeamList.map((item: any) => {
      item.disabled = this.formData.userList.some((user: any) => user.name === item.teamName)
      return item
    })
  }

  get formatMachineList () {
    const machineNames = this.machineList.map((item: any) => item.machineryName)
    return [...new Set(machineNames)]
  }

  get formatMaterialList () {
    const materialNames = this.materialList.map((item: any) => item.materialName)
    return [...new Set(materialNames)]
  }

  // get summarizeList () {
  //   const arr = [] as any
  //   this.formData.flowOrderList.forEach((item: any) => {
  //     if (item.feedBackContent) {
  //       arr.push(item.feedBackContent)
  //     }
  //   })
  //   return arr
  // }

  created () {
    if (this.operatingId) {
      this.loadData()
    }
    this.loadMaterialData()
    // this.init() // 初始化富文本
  }

  // destroyed () {
  //   // 销毁编辑器
  //   this.summarize.destroy()
  //   this.summarize = null
  // }

  // 富文本初始化
  // init () {
  //   this.$nextTick(() => {
  //     this.summarize = initWangEdit('#seedlingIntroduce', { placeholder: '请输入今日总结' })
  //     this.summarize.create()
  //     console.log(this.summarize)
  //     // 动态监听富文本改变
  //     this.summarize.config.onchange = (html: string) => {
  //       this.formData.summarize = html
  //       if (html) {
  //         ;(this.$refs.form as ElForm).clearValidate()
  //       }
  //     }
  //   })
  // }

  loadData () {
    this.$axios.get(this.$apis.operationmanage.selectOperatingByOperatingId, {
      operatingId: this.operatingId
    }).then(res => {
      ;(res.userList || []).map((item: any) => {
        item.isEdit = false
      })
      ;(res.materialList || []).map((item: any) => {
        item.isEdit = false
      })
      ;(res.machineryList || []).map((item: any) => {
        item.isEdit = false
      })
      ;(res.planList || []).map((item: any) => {
        item.isEdit = false
      })
      this.formData = res || {}
    })
  }

  loadMachineData () {
    this.$axios.get<Page<Machine>>(this.$apis.material.selectMachineryByPage, {
      projectId: this.formData.projectId
    }).then(res => {
      // console.log('机械列表', res.list)
      this.machineList = res.list
    })
  }

  @Watch('formData.projectId')
  projectChange (newId: string, oldId: string) {
    if (newId && oldId) {
      this.formData.attendanceIdList = [] // 重置出勤人员选中id
      this.formData.absenceIdList = [] // 重置缺勤人员选中id
      this.formData.userList = [] // 重置人员统计
      this.formData.machineryList = []// 重置机械统计
      this.formData.materialList = []// 重置材料统计
      this.formData.flowOrderList = [] // 重置养护工单
      this.formData.planList = [] // 重置养护工单
      this.formData.summarize = '' // 重置今日总结
    }

    this.getArea()
    this.loadMachineData() // 获取机械
    this.loadProjectUserList() // 项目用户列表
    this.loadWorkerTeamList() // 人员统计-班组名称
  }

  loadWorkerTeamList () {
    this.$axios.get(this.$apis.worker.selectProjectUserTeamByList, {
      projectId: this.formData.projectId
    }).then(res => {
      this.workerTeamList = res.list || []
    })
  }

  loadWorkOrder () {
    this.$axios.get(this.$apis.newOrder.selectFlowOrderByPage, {
      page: this.page,
      ...this.workOrderForm,
      projectId: this.formData.projectId
    }).then(res => {
      this.workOrderItems = res.list
      this.total = res.total
    })
  }

  onSearchWorkOrder () {
    this.page = 1
    this.loadWorkOrder()
  }

  loadMaterialData () {
    this.$axios.get<Page<Material>>(this.$apis.material.selectMaterialByList).then(res => {
      // console.log('loadMaterialData', res.list)
      this.materialList = res.list
    })
  }

  loadProjectUserList () {
    this.$axios.get<Page<ProjectUser>>(this.$apis.project.selectProjectUserByList, {
      projectId: this.formData.projectId
    }).then(res => {
      this.projectUserList = res.list || []
    })
  }

  workteamChange (data: string, row: any) {
    const current = this.workerTeamListData.find(item => item.teamName === data)
    if (current) {
      row.teamLeader = current.teamManagerName || ''
    } else {
      row.teamLeader = ''
    }
  }

  getSubtotal (price: string, quantity: string, overtimeHours?: (string | undefined)) {
    if (/^(0|[1-9]\d*)(.\d{1,2})?$/.test(price) && /^[1-9]\d*$/.test(quantity)) {
      return parseFloat((parseFloat(price) * parseInt(quantity) * parseInt(overtimeHours || '1')).toFixed(2)).toFixed(2)
    } else {
      return ''
    }
  }

  getSubtotal2 (price: string, quantity: string, overtimeHours?: (string | undefined)) {
    if (/^(0|[1-9]\d*)(.\d{1,2})?$/.test(price) && /^[1-9]\d*$/.test(quantity)) {
      return parseFloat((parseFloat(price || '0') * parseInt(quantity) * parseInt(overtimeHours || '0')).toFixed(2)).toFixed(2)
    } else {
      return ''
    }
  }

  saveUser (row: any) {
    if (!row.name) {
      this.$message({ type: 'warning', message: '请选择班组' })
      return
    }
    if (!/^(0|[1-9]\d*)(.\d{1,2})?$/.test(row.unitPrice)) {
      this.$message({ type: 'warning', message: '工单价格式错误' })
      return
    }
    if (!/^[1-9]\d*$/.test(row.quantity)) {
      this.$message({ type: 'warning', message: '人数为正整数' })
      return
    }
    row.laborCostSubtotal = this.getSubtotal(row.unitPrice, row.quantity) // 人工成本小计
    if (row.overtimeUnitPrice && !/^(0|[1-9]\d*)(.\d{1,2})?$/.test(row.overtimeUnitPrice)) {
      this.$message({ type: 'warning', message: '加班单价格式错误' })
      return
    }
    if (row.overtimeHours && !/^[1-9]\d*$/.test(row.overtimeHours)) {
      this.$message({ type: 'warning', message: '加班时长为正整数' })
      return
    }
    // if (row.overtimeUnitPrice && row.overtimeHours) {
    row.overtimeCostSubtotal = this.getSubtotal2(row.overtimeUnitPrice, row.overtimeHours, row.quantity)
    // console.log('row.overtimeCostSubtotal', row.overtimeCostSubtotal)
    row.total = (parseFloat(row.laborCostSubtotal) + parseFloat(row.overtimeCostSubtotal || 0)).toFixed(2)
    row.isEdit = false
  }

  saveMaterial (row: any) {
    if (!row.name) {
      this.$message({ type: 'warning', message: '请选择材料名称' })
      return
    }
    if (!row.materialSpecification) {
      this.$message({ type: 'warning', message: '请选择规格' })
      return
    }
    if (!row.supplierId) {
      this.$message({ type: 'warning', message: '请选择供应商' })
      return
    }
    if (!row.unit) {
      this.$message({ type: 'warning', message: '请输入单位' })
      return
    }
    if (!row.notConsumable) {
      this.$message({ type: 'warning', message: '请选择是否易耗' })
      return
    }
    if (!/^(0|[1-9]\d*)(.\d{1,2})?$/.test(row.unitPrice)) {
      this.$message({ type: 'warning', message: '单价格式错误' })
      return
    }
    if (!/^[1-9]\d*$/.test(row.quantity)) {
      this.$message({ type: 'warning', message: '数量为正整数' })
      return
    }
    row.total = this.getSubtotal(row.unitPrice, row.quantity)
    row.isEdit = false
  }

  savePlan (row: any) {
    if (!row.orderTitle) {
      this.$message({ type: 'warning', message: '请选择工作主题' })
      return
    }
    if (!row.orderContent) {
      this.$message({ type: 'warning', message: '请选择工作详情' })
      return
    }
    if (!row.orderAreaName) {
      this.$message({ type: 'warning', message: '请选择作业区域' })
      return
    }
    if (!row.orderUserName) {
      this.$message({ type: 'warning', message: '请选择责任人' })
      return
    }
    row.isEdit = false
  }

  saveMachine (row: any) {
    if (!row.name) {
      this.$message({ type: 'warning', message: '请选择机械名称' })
      return
    }
    if (!row.machineModel) {
      this.$message({ type: 'warning', message: '请选择机械型号' })
      return
    }
    if (!row.supplierId) {
      this.$message({ type: 'warning', message: '请选择供应商' })
      return
    }
    if (!/^(0|[1-9]\d*)(.\d{1,2})?$/.test(row.unitPrice)) {
      this.$message({ type: 'warning', message: '单价格式错误' })
      return
    }
    if (!/^[1-9]\d*$/.test(row.quantity)) {
      this.$message({ type: 'warning', message: '台班为正整数' })
      return
    }
    row.total = this.getSubtotal(row.unitPrice, row.quantity)
    row.isEdit = false
  }

  formatMachineModel (name: string) {
    if (name) {
      const items = JSON.parse(JSON.stringify(this.machineList))
      const data = items.filter((item: any) => item.machineryName === name).map((item: any) => item.specificationModel)
      return [...new Set(data)]
    } else {
      return []
    }
  }

  formatMachineSupplier (name: string, model: string) {
    if (name && model) {
      const items = JSON.parse(JSON.stringify(this.machineList))
      return items.filter((item: any) => item.machineryName === name && item.specificationModel === model).map((item: any) => {
        return {
          supplierName: item.supplierName,
          supplierId: item.supplierId
        }
      })
    } else {
      return []
    }
  }

  formatMaterialModel (name: string) {
    if (name) {
      const items = JSON.parse(JSON.stringify(this.materialList))
      const data = items.filter((item: any) => item.materialName === name).map((item: any) => item.specifications)
      return [...new Set(data)]
    } else {
      return []
    }
  }

  formatMaterialSupplier (name: string, model: string) {
    if (name && model) {
      const items = JSON.parse(JSON.stringify(this.materialList))
      const data = items.filter((item: any) => item.materialName === name && item.specifications === model)
      const supplierArr: any = []
      data.forEach((item: any) => {
        if (!supplierArr.some((material: any) => material.supplierId === item.supplierId)) {
          supplierArr.push({
            supplierName: item.supplierName,
            supplierId: item.supplierId
          })
        }
      })
      return supplierArr
    } else {
      return []
    }
  }

  getUserSummaries (params: any) {
    const sum1 = params.data.reduce((prev: any, next: any) => {
      return prev + parseInt(next.quantity || 0)
    }, 0)
    const sum2 = params.data.reduce((prev: any, next: any) => {
      return prev + parseFloat(next.laborCostSubtotal || 0)
    }, 0).toFixed(2)
    const sum3 = params.data.reduce((prev: any, next: any) => {
      return prev + parseInt(next.overtimeHours || 0)
    }, 0)
    const sum4 = params.data.reduce((prev: any, next: any) => {
      return prev + parseFloat(next.overtimeCostSubtotal || 0)
    }, 0).toFixed(2)
    const sum5 = params.data.reduce((prev: any, next: any) => {
      return prev + parseFloat(next.total || 0)
    }, 0).toFixed(2)
    return ['合计', '', '', sum1, sum2, '', '', sum3, sum4, sum5]
  }

  getMachineSummaries (params: any) {
    const sum = params.data.reduce((prev: any, next: any) => {
      return prev + parseFloat(next.total || 0)
    }, 0).toFixed(2)
    return ['合计', '', '', '', '', '', '', sum, '', '']
  }

  getMaterialSummaries (params: any) {
    const sum = params.data.reduce((prev: any, next: any) => {
      return prev + parseFloat(next.total || 0)
    }, 0).toFixed(2)
    return ['合计', '', '', '', '', '', '', sum, '', '']
  }

  machineNameChange (row: any) {
    row.machineModel = ''
    row.supplierId = ''
    row.supplierName = ''
  }

  machineModelChange (row: any) {
    row.supplierId = ''
    row.supplierName = ''
  }

  materialNameChange (row: any) {
    row.materialSpecification = ''
    row.supplierId = ''
    row.supplierName = ''
  }

  materialModelChange (row: any) {
    row.supplierId = ''
    row.supplierName = ''
  }

  supplierChange (supplierId: string, row: any) {
    const current = this.machineList.find(item => item.supplierId === supplierId && item.machineryName === row.name && item.specificationModel === row.machineModel) as Machine
    row.whetherOil = current.whetherOil // 是否含油1是2否
    row.unitPrice = current.unitPrice // 单价
    row.unit = '' // 单位
    row.quantity = '' // 台班/小时
    row.total = '' // 合计(元)
    console.log('current', current)
    row.supplierName = current.supplierName
  }

  materialSupplierChange (supplierId: string, row: any) {
    const current = this.materialList.find(item => item.supplierId === supplierId && item.materialName === row.name && item.specifications === row.materialSpecification) as Material
    row.unit = current.company || '' // 单位
    row.supplierName = current.supplierName
  }

  addMachine () {
    if (!this.formData.projectId) {
      this.$message({ type: 'warning', message: '请选择项目名称' })
      return
    }
    (this.formData.machineryList as any).push({
      name: '', // 机械名称
      machineModel: '', // 机械型号
      quantity: '', // 台班/小时
      supplierId: '', // 供应商id
      supplierName: '',
      total: '', // 合计(元)
      unit: '', // 单位
      unitPrice: '', // 单价
      whetherOil: '', // 是否含油1是2否
      remarks: '', // 备注
      isEdit: true
    })
  }

  addUser () {
    if (!this.formData.projectId) {
      this.$message({ type: 'warning', message: '请选择项目名称' })
      return
    }
    (this.formData.userList as any).push({
      teamLeader: '', // 班组负责人
      name: '', // 班组名称
      laborCostSubtotal: '', // 人工成本小计
      overtimeCostSubtotal: '', // 加班成本小计
      overtimeHours: '', // 加班时长
      overtimeUnitPrice: '', // 加班单价（元）
      quantity: '', // 人数
      total: '', // 合计(元)
      unit: '', // 单位
      unitPrice: '', // 工单价
      remarks: '', // 备注
      isEdit: true
    })
  }

  addMaterial () {
    (this.formData.materialList as any).push({
      name: '', // 材料名称
      materialSpecification: '', // 材料规格
      notConsumable: '', // 是否易耗1是2否
      quantity: '', // 数量
      supplierId: '', // 供应商id
      supplierName: '', //
      total: '', // 合计(元)
      unit: '', // 单位
      unitPrice: '', // 单价
      remarks: '', // 备注
      isEdit: true
    })
  }

  addPlan () {
    if (!this.formData.projectId) {
      this.$message({ type: 'warning', message: '请选择项目名称' })
      return
    }
    (this.formData.planList as any).push({
      orderTitle: '',
      orderContent: '',
      orderAreaId: '',
      orderUserId: '', // 备注
      isEdit: true
    })
  }

  loadWorkOrderType () {
    this.$axios.get<Page<OrderTypeDetail>>(this.$apis.newOrder.selectFlowOrderTypeByList).then(res => {
      this.typeList = res.list || []
    })
  }

  selectable (row: OrderDetail) {
    return !this.formData.flowOrderList.some((item: any) => item.orderId === row.orderId)
  }

  delWorkOrder (index: number) {
    this.formData.flowOrderList.splice(index, 1)
  }

  delMachine (index: number) {
    this.formData.machineryList.splice(index, 1)
  }

  delUserList (index: number) {
    this.formData.userList.splice(index, 1)
  }

  delMaterial (index: number) {
    this.formData.materialList.splice(index, 1)
  }

  delPlanList (index: number) {
    this.formData.planList.splice(index, 1)
  }

  @Watch('dialogVisible')
  dialogVisibleChange (visible: boolean) {
    console.log('visible', visible)
    if (!visible) {
      this.table.clearSelection()
    }
  }

  showWorkOrder () {
    if (!this.formData.projectId) {
      this.$message.warning('请先选择项目名称')
      return
    }
    this.page = 1
    this.dialogVisible = true
    this.loadWorkOrderType()
    this.loadWorkOrder()
    this.$nextTick(() => {
      this.formData.flowOrderList.forEach(item => {
        this.table.toggleRowSelection(item, true)
      })
    })
  }

  // confirmWorkOrder () {
  //   // console.log('选择养护工单', this.formData.flowOrderList)
  //   this.summarize.txt.html('')
  //   this.formData.summarize = ''
  //   const items = (this.table as any).selection
  //   this.formData.flowOrderList = JSON.parse(JSON.stringify(items))
  //   this.dialogVisible = false

  //   let summarize = ''
  //   this.formData.flowOrderList.forEach((item: any, index: any) => {
  //     if (item.feedBackContent) {
  //       summarize = `${index + 1}、${item.feedBackContent}<br>`
  //       this.summarize.txt.append(summarize)
  //       this.formData.summarize += summarize
  //     }
  //   })
  // }

  confirmWorkOrder () {
    // console.log('选择养护工单', this.formData.flowOrderList)
    this.formData.summarize = ''
    const items = (this.table as any).selection
    this.formData.flowOrderList = JSON.parse(JSON.stringify(items))
    this.dialogVisible = false
    let summarize = ''
    console.log(this.formData.flowOrderList)
    this.formData.flowOrderList.forEach((item: any, index: any) => {
      if (index === this.formData.flowOrderList.length - 1) {
        if (item.feedBackContent) {
          summarize = `${summarize}${index + 1}、${item.feedBackContent}`
        }
      } else {
        if (item.feedBackContent) {
          summarize = `${summarize}${index + 1}、${item.feedBackContent}\n`
        }
      }
    })
    this.formData.summarize = summarize
  }

  onImgDetails () {
    if (!this.formData.projectId) {
      this.$message.warning('请先选择项目名称')
      return
    }
    if (!this.formData.dateStr) {
      this.$message.warning('请先选择日报日期')
      return
    }
    // console.log('详情')
    this.imglistShow = true
    // selectClockList
  }

  closeImgDetails () {
    this.imglistShow = false
  }

  submit () {
    this.form.validate().then(() => {
      let url = this.$apis.operationmanage.insertOperating
      if (this.operatingId) {
        url = this.$apis.operationmanage.updateOperating
      }
      const form = JSON.parse(JSON.stringify(this.formData))
      if (!this.formData.flowOrderList.length && !this.formData.machineryList.length && !this.formData.materialList.length && !this.formData.userList.length) {
        this.$message({ type: 'warning', message: '请至少添加一项统计信息' })
        return
      }
      if (!this.formData.planList.length) {
        this.$message({ type: 'warning', message: '请至少添加一项明日计划信息' })
        return
      }
      if (this.formData.userList.some((item: any) => item.isEdit)) {
        this.$message({ type: 'warning', message: '请保存人员统计' })
        return
      }
      if (this.formData.machineryList.some((item: any) => item.isEdit)) {
        this.$message({ type: 'warning', message: '请保存机械统计' })
        return
      }
      if (this.formData.materialList.some((item: any) => item.isEdit)) {
        this.$message({ type: 'warning', message: '请保存材料统计' })
        return
      }
      if (this.formData.planList.some((item: any) => item.isEdit)) {
        this.$message({ type: 'warning', message: '请保存明日计划' })
        return
      }
      form.userList.forEach((item: any) => {
        delete item.isEdit
      })
      form.materialList.forEach((item: any) => {
        delete item.isEdit
        delete item.supplierName
      })
      form.machineryList.forEach((item: any) => {
        delete item.isEdit
        delete item.supplierName
      })
      form.planList.forEach((item: any) => {
        const data = this.areaList.filter((item1: any) => item1.areaName === item.orderAreaName)
        item.orderAreaId = data[0].projectAreaId
        const data1 = this.projectUserList.filter((item1: any) => item1.name === item.orderUserName)
        item.orderUserId = data1[0].userId
        delete item.isEdit
        delete item.orderAreaName
        delete item.orderUserName
      })
      this.$axios.post(url, form).then(() => {
        this.$router.back()
      })
    })
  }

  getArea () {
    this.$axios.get(this.$apis.project.selectProjectAreaByList, {
      projectId: this.formData.projectId,
      notAllArea: '1'
    }).then((res) => {
      this.areaList = res.list || []
    })
  }
}
