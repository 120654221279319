
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import NoData from '@/components/NoData.vue'
import { FileInfo } from '@/types/uploadFile'
@Component({
  name: 'attenanceImglist',
  components: { NoData },
  filters: {
    previewList: function (value: FileInfo[]) {
      return value.map((item: any) => {
        return item.imgUrl
      })
    }
  }
})
export default class OperationRecord extends Vue {
  @Prop() readonly imglistShow!: boolean;
  @Prop() readonly projectId!: string;
  @Prop() readonly dateStr!: string;
  private loading = false
  private imgList: any = [
    {
      imgUrl: '',
      objectName: ''
    }
  ]

  @Watch('imglistShow')
  imglistShowChange (val: any) {
    if (val) {
      this.loadData()
    }
  }

  loadData () {
    this.loading = true
    this.$axios.get(this.$apis.operationmanage.selectClockList, {
      projectId: this.projectId,
      clockDate: this.dateStr
    }).then(res => {
      console.log('考勤图片', res)
      this.imgList = res
    }).finally(() => {
      this.loading = false
    })
  }

  handleClose () {
    this.$emit('update:imglistShow', false)
    this.imgList = [
      {
        imgUrl: '',
        objectName: ''
      }
    ]
  }
}
